import './Assets/Scripts/neosEvents.js'
import './Template/_Fragment/Navigation/Navigation';
import './Component/PageCardSlider/PageCardSlider';
import './Template/_Fragment/PageHeader/PageHeaderSlider.js';
import './Template/Product/Product';
import './Component/Accordion/Accordion';
import './Component/TextMediaExpandable/TextMediaExpandable';

import { pageHeaderSlider } from './Template/_Fragment/PageHeader/PageHeaderSlider.js';
import { pageCardSlider } from './Component/PageCardSlider/PageCardSlider';

document.addEventListener('Neos.NodeCreated', function(event) {
    const element = event.detail.element;

    if(element.querySelector('.pageHeaderSlider')) {
        pageHeaderSlider()
    }
    if(element.querySelector('.pageCardSlider__content')) {
        pageCardSlider()
    }
})