import Swiper from "swiper";
import { Pagination, Autoplay, Navigation, A11y } from "swiper";
import { initNeosEventItemCreated } from "../../Assets/Scripts/neosEvents";
import { initNeosEventItemSelected } from "../../Assets/Scripts/neosEvents";

export function pageCardSlider() {
    document.querySelectorAll(".pageCardSlider__content").forEach((slider) => {
        const swiper = new Swiper(slider, {
            modules: [Pagination, Autoplay, Navigation, A11y],
            slidesPerView: "auto",
            spaceBetween: 0,
            direction: "horizontal",
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    
        initNeosEventItemSelected(swiper, slider);
        initNeosEventItemCreated(swiper, slider);
    });
}

pageCardSlider()
