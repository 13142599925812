document.querySelectorAll(".navigation__item").forEach((item) => {
    if (item.querySelector("a")) {
        const pathname = location.pathname;
        const uri = item.querySelector("a").getAttribute("href");

        if (pathname == uri) {
            item.classList.add("active");
        } else {
            item.classList.remove("active");
        }
    }
});

document.querySelectorAll(".navigation__sub__item").forEach((subItem) => {
    if (subItem.querySelector("a")) {
        const pathname = location.pathname;
        const uri = subItem.querySelector("a").getAttribute("href");
        const parent = subItem.closest(".navigation__item");

        if (pathname == uri) {
            subItem.classList.add("active");
            parent.classList.add("active");
        } else {
            subItem.classList.remove("active");
        }
    }
});

document.querySelector(".navigation__toggle").addEventListener("click", () => {
    document.querySelector("nav.navigation").classList.toggle("active");
    document.querySelector(".navigation__toggle").classList.toggle("active");
});
