if (document.querySelector(".product__toggleShops")) {
    document.querySelectorAll(".product__toggleShops").forEach((item) => {
        item.addEventListener("click", () => {
            document.querySelector("#modal__shops").classList.add("active");
        });
    });
    document.querySelector(".modal__window__close").addEventListener("click", () => {
        document.querySelector(".modal").classList.remove("active");
    });
    document.querySelector(".modal__background-close").addEventListener('click', () => {
        document.querySelector("#modal__shops").classList.remove("active");
    })
}
