import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, A11y} from "swiper";
import { initNeosEventItemCreated } from "../../../Assets/Scripts/neosEvents";
import { initNeosEventItemSelected } from "../../../Assets/Scripts/neosEvents";

export function pageHeaderSlider() {
    document.querySelectorAll(".pageHeaderSlider").forEach((slider) => {
        const isNeosBackend = document.body.classList.contains('neos-backend')

        const swiperItems = Array.from(slider.querySelectorAll('.swiper-slide'))
        if(swiperItems.length <= 1) {
            return
        }
        
        const swiper = new Swiper(slider, {
            modules: [Pagination, Autoplay, Navigation, A11y],
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            loop: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });

        if (slider.hasAttribute("data-autoplay") && !isNeosBackend) {
            new IntersectionObserver((entries, observer) => {
                if (entries[0].isIntersecting) {
                    observer.disconnect();
                    swiper.params.autoplay = {
                        delay: 7500,
                    };
                    swiper.autoplay.start();
                }
            }).observe(slider);
        }
    
        initNeosEventItemSelected(swiper, slider);
        initNeosEventItemCreated(swiper, slider);
    });
}

pageHeaderSlider()